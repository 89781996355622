.gPg.fdbOvrPg > .dtFltBx > .dateFilter {
  width: auto;
}
.gPg.fdbOvrPg > .dtFltBx > .dateFilter > .dwnldCsv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  padding: 0 10px;
}
.gPg.fdbOvrPg > .dtFltBx > .dateFilter > .dwnldCsv:hover {
  background-color: var(--generic-hover-bg-color);
}
.gPg.fdbOvrPg > .dtFltBx > .dateFilter > .dwnldCsv > img {
  height: 20px;
  margin-right: 5px;
}
.gPg.fdbOvrPg > .dtFltBx > .dateFilter > .dwnldCsv > p {
  margin-bottom: 0;
}
.gPg.fdbOvrPg > .dtFltBx > .dateFilter > .dwnldCsv > span {
  margin-left: 10px;
}


.gPg.fdbOvrPg > .fbdOvrBd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt {
  flex: 1;
  margin-right: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt {
  width: 245px;
}


.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox {
  display: flex;
  flex-direction: column;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .noDataBox {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel {
  width: 245px;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .left {
  width: 200px;
  border-right: 1px solid #e6e6e7;
  display: flex;
  flex-direction: column;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .left > p {
  margin: 0;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .left > p.title {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .left > p.title > span.tltp {
  cursor: pointer;
  margin-left: 5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .left > p.value {
  font-size: var(--font-size-numeric-value);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox> .contentBox > .dataBox > .overviewBox > .right > .bar {
  width: 100%;
  height: 50px;
  border-radius: var(--generic-border-radius);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .bar > .positive {
  background-color: #52ced5;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .bar > .negative {
  background-color: #fd868b;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .info > .item {
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .info > .item > .dot {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-top: 5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .info > .item.positive > .dot {
  background-color: #52ced5;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .info > .item.negative > .dot {
  background-color: #fd868b;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .info > .item > .numbers {
  flex: 1;
  margin-left: 6px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .info > .item > .numbers > p {
  margin: 0;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .right > .info > .item > .numbers > p.label {
  margin-bottom: 3px;
}


.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .countBox {
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox > .listBox {
  display: flex;
  flex-direction: column;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry {
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .left {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .left > .smlRslt {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .left > .smlRslt > img {
  margin-right: 10px;
  height: 30px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .left > .starRslt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .left > .starRslt > img {
  margin-top: -5px;
  height: 20px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .name {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .extra {
  display: flex;
  flex-direction: row;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .extra > .date {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .extra > .source {
  margin-left: 5px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .directedTo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .directedTo > span {
  margin-left: 5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .directedTo > span > img {
  height: 24px;
  margin-left: 5px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .roomNum {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  b1ackground-color: #f1f2f4;
  p1adding: 6px 12px;
  border-radius: var(--generic-border-radius);
  margin-top: 2px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .left > .collectionSource {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  b1ackground-color: #f1f2f4;
  p1adding: 6px 12px;
  border-radius: var(--generic-border-radius);
  margin-top: 10px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .answs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .answs > p.ans {
  font-style: italic;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .answs > .ans.mo5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .answs > .ans.mo5 > .subq {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  gap: 10px;
  /* border-bottom: 1px solid var(--primary-text-color-light); */
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .answs > .ans.mo5 > .subq > p {
  margin-bottom: 0px;
}




.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .feedbackText {
  word-break: break-all;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .submitRow > .buttonWrap > .gButton {
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--primary-text-color-light);
  color: var(--primary-text-color-light);
  padding: 5px 10px;
  font-weight: var(--font-weight-bold);
  cursor: default;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox> .replyTextBox {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .vBar {
  width: 8px;
  height: 40px;
  margin: 0px 20px 20px 0px;
  background-color: #e6e6e7;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > textarea {
  width: calc(100% - 0px);
  padding: 12px;
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(37, 39, 52, 0.1);
  resize: vertical;
  margin-bottom: 10px;
  min-height: 120px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .gErr {
  margin-bottom: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .submitRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .submitRow > .lt {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .submitRow .gButton:first-child {
  
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .submitRow .gButton > span {
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--primary-text-color-light);
  color: var(--primary-text-color-light);
  padding: 5px 10px;
  font-weight: var(--font-weight-bold);
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .rInf {
  margin-top: 5px;
  color: var(--primary-text-color-light);
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .vBar {
  width: 8px;
  height: 40px;
  margin: 0px 20px 20px 0px;
  background-color: #e6e6e7;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .content > .topRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .content > .topRow > .yourReply {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .content > .topRow > .showReply {
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin-bottom: 10px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel {
  width: 245px;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
}


.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .header > p {
  color: var(--primary-text-color);
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .header > p.reset {
  color: var(--gte-primary-color);
  cursor: pointer;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .header > p.reset > span {
  margin-right: 5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .search {
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .search > img.label {
  position: absolute;
  margin: 12px 0 0 10px;
  cursor: pointer;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .search > input {
  width: calc(100% - 34px);
  padding: 12px 18px 12px 36px;
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(37, 39, 52, 0.1);
  resize: vertical;
  background-color: #e6e6e7;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .search > img.clear {
  position: absolute;
  margin: 16px 0px 0px -20px;
  cursor: pointer;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .search > span.submit {
  position: absolute;
  1margin: -43px 0px -10px 211px;
  cursor: pointer;
  background-color: var(--gte-primary-color);
  padding: 12px;
  border-top-right-radius: var(--generic-border-radius);
  border-bottom-right-radius: var(--generic-border-radius);
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .search > span.submit > i {
  color: #FFFFFF;
}

.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox {
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox > .title {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox > .dRows {
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox > .dRows > .dRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox > .dRows > .dRow > .left {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox > .dRows > .dRow > .right {
  width: 50px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox > .dRows > .dRow > .left > input {
  margin-right: 10px;
  font-size: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox > .dRows > .dRow > .left > .label {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  cursor: pointer;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .filterBox > .dRows > .dRow > .right {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  text-align: right;
}
.gPg.fdbOvrPg > .fbdOvrBd > .rt > .filterPanel > .lineBreak {
  width: 245px;
  height: 1px;
  margin: 20px 0;
  background-color: #e6e6e7;
}


/* ************** guest info - dropdown  ********************** */

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown > .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 10px;
  font-size: var(--font-size-primary);
  border: none;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown > .dropbtn > span {
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--primary-text-color-light);
  color: var(--primary-text-color-light);
  padding: 5px 10px;
  font-weight: var(--font-weight-bold);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown.open > .dropbtn > span {
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--gte-primary-color);
  color: var(--gte-primary-color);
  padding: 5px 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown > .dropbtn > span > i {
  margin-left: 5px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown > .dropdown-content {
  left: -155px;
  top: 40px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown > .dropdown-content > .dcRow {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown > .dropdown-content a:hover {background-color: var(--generic-hover-bg-color);}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown.guestInfo.open .dropdown-content {display: block;}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown.guestInfo > .dropbtn {
  padding: 0px;
  background-color: #FFFFFF;
  cursor: pointer;
  1border-radius: 20px 16px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .guestInfo > .right > .dropdown.guestInfo > .dropbtn:hover {
  b1ackground-color: var(--generic-hover-bg-color);

}

/* ************************************ */

.sFGuestInfoBox {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.sFGuestInfoBox p {
  margin: 0;
}
.sFGuestInfoBox > .closeRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}
.sFGuestInfoBox > .closeRow > span {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.sFGuestInfoBox > .nameBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e7;
}
.sFGuestInfoBox > .nameBox > p.name {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-top: 20px;
  margin-bottom: 2px;
}
.sFGuestInfoBox > .nameBox > p.visits > span {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}

.sFGuestInfoBox > .contactBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e7;
}
.sFGuestInfoBox > .contactBox > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.sFGuestInfoBox > .contactBox > p.email {
  margin-bottom: 16px;
  word-break: break-all;
}

.sFGuestInfoBox > .datesBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0 0 0;
}
.sFGuestInfoBox > .datesBox > p {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.sFGuestInfoBox > .datesBox > p.checkInDate {
  margin-bottom: 16px;
}
.sFGuestInfoBox > .datesBox > p > span {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-right: 20px;
}


/* start - translate row */

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 0 0px;
  margin-bottom: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIconDiv {
  margin-right: 5px;
  margin-top: -5px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateToLang {
  margin-right: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateToLang > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon {
  margin-right: 10px;
  margin-left: 0px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .viewOriginal {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
/* ************** dropdown menu - language Selector ********************** */

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 2px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropdown-content {
  left: 30px;
  top: 1px;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropdown.open .dropdown-content {display: block;}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropbtn > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .right > .translateRow > .translateIcon .dropbtn > span.translatedToLang {
  text-decoration: underline;
}

/* *************** start - translate row ********************* */


.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .translateInProgress {
  position: absolute;
  top:0px;
  right:0px;
  width:100%;
  height:100%;
  background-color:#eceaea;
  background-color: #0000001a;
  /* background-image:url('ajax-loader.gif');
  background-size: 50px;
  background-repeat:no-repeat;
  background-position:center; */
  /* z-index:10000000; */
  /* opacity: 0.4; */
  /* filter: alpha(opacity=40); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .listBox > .singleEntry > .translateInProgress > span {
  padding: 20px;
  background: #FFFFFF;
  border-radius: var(--generic-border-radius);
}

.reviewStars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
}
.reviewStars:before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }




/**************/

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.ansStats {
  flex-direction: column;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.ansStats > p.title {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.ansStats > .ansRatChrtWrp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: stretch;
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats {
  flex-direction: column;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .title {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx {
  /* border-bottom: 1px solid #c8c5c5; */
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
  padding: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .label {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx {
  padding: 10px 0 20px 20px;
  /* border-bottom: 1px solid #c8c5c5; */
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .scl {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .scl > span {
  padding: 4px 6px;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #c8c5c5;
  color: var(--primary-text-color-light);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn {
  margin-bottom: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.out5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.out5 > .rt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.out5 > .rt > span.avg {
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #c8c5c5;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.out5 > .rt > span.percent {
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}

.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.yesNo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.yesNo > .lt {
  flex: 1;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.yesNo > .lt {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.yesNo > .lt > .percentWidth {
  height: 30px;
  background-color: #a5c1f9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.yesNo > .lt > .percentWidth > span {
  margin-right: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.yesNo > .rt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.yesNo > .rt > span.avg {
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #c8c5c5;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.yesNo > .rt > span.percent {
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}


.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.multO5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.multO5 > .rslt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.multO5 > .rslt > .percent {
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .qstn.multO5 > .rslt > .avg {
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #c8c5c5;
}


.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs {
  padding: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs.multO5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs.multO5:nth-child(even) {
  background-color: #f7f6f6;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs.multO5 > .lt {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs.multO5 > .lt > .percentWidth {
  height: 30px;
  background-color: #a5c1f9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs.multO5 > .lt > .percentWidth > span {
  margin-right: 10px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs.multO5 > .rt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs.multO5 > .rt > span.avg {
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #c8c5c5;
}
.gPg.fdbOvrPg > .fbdOvrBd > .lt > .innerBox > .contentBox > .dataBox > .overviewBox.genAnsStats > .sctnsBx > .qstnsBx > .answs.multO5 > .rt > span.percent {
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}

.sctnTitle {
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
}
.gPg.cmpgnPg > h3 {
  margin-bottom: 20px;
}
.gPg.cmpgnPg > h3 > a {
    color: var(--gte-primary-color);
}
.cmpgnSngl {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px;
    max-width: 800px;
    /* border-radius: var(--generic-border-radius);
    border: 1px solid var(--generic-border-color); */
  }
  .cmpgnSngl > .teRw {
    margin: 0 0 20px 0;
  }
  .cmpgnSngl > .teRw.hdr {
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-semi-bold);
  }
  .cmpgnSngl > .teRw.hasTgl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .cmpgnSngl > .teRw.hasTgl > .lt {
    font-size: var(--font-size-primary);
    color: var(--primary-text-color);
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
  }
  .cmpgnSngl > .teRw.hasTgl > .lt > .title {
    font-weight: var(--font-weight-bold);
    margin-bottom: 5px;
  }
  .cmpgnSngl > .teRw.hasTgl > .lt > .desc {
    color: var(--primary-text-color-light);
    margin-bottom: 20px;
  }
  .cmpgnSngl > .teRw.hasTgl > .lt > .kVGnrc {
    color: var(--primary-text-color-light);
    line-height: 1.53;
    margin-bottom: 2px;
  }
  .cmpgnSngl > .teRw.hasTgl > .lt > .kVGnrc > span {
    color: var(--primary-text-color);
  }
  
  .cmpgnSngl > .teRw.hasTgl > .rt {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  
  .cmpgnSngl > .teRw.cstmTypVal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  .cmpgnSngl > .teRw.cstmTypVal > .lt,
  .cmpgnSngl > .teRw.cstmTypVal > .rt {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .teSngl > .teRw.cstmTypVal > .rt > .gLbl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .cmpgnSngl > .teRw.trgtRw {
    margin-bottom: 40px;
  }
  .cmpgnSngl > .teRw.trgtRw > .numGusttBx {
    margin-top: 10px;
  }
  .cmpgnSngl > .teRw.doNotSendRw > .lt > .chkBxWrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
  }
  .cmpgnSngl > .teRw.doNotSendRw > .lt > .chkBxWrp > label {
    margin-bottom: 0;
  }
  
  .cmpgnSngl > .teRw.tplNmRw {
    margin-bottom: 40px;
  }
  .cmpgnSngl > .teRw.tplNmRw > .lt > .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .cmpgnSngl > .teRw.tplNmRw > .lt > .title > .etNm {
    color: var(--primary-text-color-light);
  }
  .cmpgnSngl > .teRw.tplNmRw > .lt > .title > .etNm > span {
    color: var(--primary-text-color);
  }
  .cmpgnSngl > .teRw.tplNmRw > .lt > .title > .etEdit {
    background-color: var(--sidebar-main-menu-active-bg);
    border-radius: var(--generic-border-radius);
    padding: 3px 6px;
    cursor: default;
  }
  
  .cmpgnSngl > .teRw.emValRw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    margin-bottom: 50px;
  }
  .cmpgnSngl > .teRw.emValRw > .lt {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .cmpgnSngl > .teRw.emValRw > .lt > .gIpt > input {
    padding-right: 40px;
  }
  .cmpgnSngl > .teRw.emValRw > .lt > .emojiIconBox {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: -30px;
    margin-right: 10px;
    height: 0;
  }
  .cmpgnSngl > .teRw.emValRw > .lt > .emojiIconBox.adjTp {
    margin-top: -49px;
  }
  .cmpgnSngl > .teRw.emValRw > .lt > .emojiBox {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .cmpgnSngl > .teRw.emPrsnlRw {
    margin-bottom: 40px;
  }
  .cmpgnSngl > .teRw.emPrsnlRw > .lt {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .cmpgnSngl > .teRw.emPrsnlRw > .lt > .gSLbl {
    font-weight: normal;
    color: var(--primary-text-color);
    font-size: var(--font-size-sub-mid-primary);
  }
  .cmpgnSngl > .teRw.emPrsnlRw > .lt > .emPrsnlSel {
    width: 300px;
  }
  .cmpgnSngl > .teRw.emPrsnlRw > .lt > .gHelpMsg {
    margin-top: 5px;
    font-size: var(--font-size-sub-mid-primary);
  }
  
  .cmpgnSngl > .teRw.sndBfrAft > .lt {
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  .cmpgnSngl > .teRw.whenRw > .lt > .whenRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .cmpgnSngl > .teRw.whenRw > .lt > .whenRow > .itemBox {
    background-color: #e6e6e7;
    border-radius: var(--generic-border-radius);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10px;
    padding: 10px;
  }
  .cmpgnSngl > .teRw.whenRw > .lt > .whenRow > .itemBox.selected {
    background-color: #4f5969;
    color: #fff;
  }
  .cmpgnSngl > .teRw.whenRw > .lt > .scheduleRow {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 20px;
  }
  .cmpgnSngl > .teRw.whenRw > .lt > .scheduleRow > .scheduleDateBox {
    width: 300px;
  }
  .cmpgnSngl > .teRw.btnRw {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .cmpgnSngl > .teRw > .lt > .errorRow {
    margin-top: 5px;
  }
  
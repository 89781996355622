.gPg.cmpgsPg > .title {
    margin-bottom: 20px;
    font-size: var(--font-size-title);
}
.gCrd.emCmgnCrd {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    border-radius: 10px;
    max-width: 1000px;
    margin-bottom: 10px;
    /* border: 1px solid #e0e0e0; */
}
.gCrd.emCmgnCrd > .lt > .sentOn {
    color: var(--sidebar-text-color);
}
.gCrd.emCmgnCrd > .rt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}
.gCrd.emCmgnCrd > .rt > .cm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.gCrd.emCmgnCrd > .rt > .cm > div {
    cursor: pointer;
    display: flex;
}
.gCrd.emCmgnCrd > .rt > .cm > .curSts {
    cursor: default;
}
.gCrd.emCmgnCrd > .rt > .cm > .curSts > span {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-weight:500;
}
.gCrd.emCmgnCrd > .rt > .cm > .curSts > span.sent {
    background-color: #ffe662;
    color: var(--primary-text-color);
}
.gCrd.emCmgnCrd > .rt > .cm > .curSts > span.scheduled {
    background-color: #6295ff;
    color: #FFFFFF;
}
.gCrd.emCmgnCrd > .lt > .title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
}
.gCrd.emCmgnCrd > .lt > p {
    margin-bottom: 5px;
}
.gCrd.emCmgnCrd > .lt > p > span {
    /* font-weight: 600; */
}
.sctn > .sctnhdr {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
}
.sctn > .sctnhdr > span > i {
    font-size: 20px;
    cursor: pointer;
}
.sctn > .sctnhdr > .gToggleWrap > .gToggle {
    margin-top: -22px;
}
.sctn > .sctnhdr > .gToggleWrap > .gToggle > label {
    cursor: pointer;
}
.sctn > .qestns > .qestn {
    background-color: #FFFFFF;
    margin-bottom: 10px;
    padding: 10px;
}
.sctn > .qestns > .qestn:last-child {
    margin-bottom: 0px;
}
.sctn > .qestns > .qestn > .titleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.sctn > .qestns > .qestn > .titleRow > .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.sctn > .qestns > .qestn > .titleRow > .right > .odrBx {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 5px;
}
.sctn > .qestns > .qestn > .titleRow > .right > .odrBx > span > i {
    font-size: 18px;
    cursor: pointer;
}
.sctn > .qestns > .qestn > .titleRow > .right > span {
    cursor: pointer;
    margin-top: 6px;
}
.sctn > .qestns > .qestn > .titleRow > .right > span > i {
    font-size: 16px;
}
.sctn > .qestns > .qestn > .qTypeRow {
    margin-bottom: 10px;
}
.sctn > .qestns > .qestn > .qTypeRow > .qestn > .titleRow > p {
    margin-bottom: 0px;
}
.sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
}
.sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt > .opt {
    background-color: #f5f5f5;
    padding: 10px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
.sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt > .opt > label {
    text-align: center;
}
.sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt > .opt > input[type="radio"] {
}
.sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt > .opt > input[type="radio"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 0 1px grey;
    margin-top: 10px;
    cursor: pointer;
  }
  .sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt > .opt > input[type="radio"]:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  
  .sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt > .opt label {
    display: block;
    cursor: pointer;
  }
  .sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt > .opt > input[type="radio"]:hover {
    box-shadow: 0 0 0 2px #3498db;
    background-color: #3498db;
  }
  .sctn > .qestns > .qestn > .qTypeRow > .qestn > .gIpt > .opt > input[type="radio"]:checked {
    box-shadow: 0 0 0 2px #0d43aa;
    background-color: #0d43aa;
  }
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > p > .addOption {
    cursor: pointer;
    margin-left: 6px;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 8px;
    padding: 8px;
    /* border-bottom: 1px solid #ccc; */
    background-color: #f5f5f5;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt:last-child {
    border-bottom: none;
    margin-bottom: 0px;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt > .odrBx {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-right: 10px;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt > .odrBx > span > i {
    font-size: 18px;
    cursor: pointer;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt > .optLabel {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   gap: 10px;
   flex: 1;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt > .optLabel > label {
    flex: 1;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt > .optLabel > span {
    width: 20px;
    cursor: pointer;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt > .optLabel > input {
  width: 100%;
  color: var(--primary-text-color);
  background-color: #FFFFFF;
  padding: 4px 6px 4px 6px;
  margin-bottom: 1px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  resize: vertical;
}
.sctn > .qestns > .qestn > .contentRow > .contentRow > .ssOptionsBox > .opt > .optLabel > input[type="checkbox"] {
    padding: 0px;
    width: 16px;
    margin-right: 7px;
    cursor: pointer;
}
.sctn > .qestns > .addQstnBtn {
  cursor: pointer;
  margin-top: 10px;
}
.sctn > .qestns > .addQstnBtn > span > i {
  margin-left: 5px;
}
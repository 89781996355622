
.gPg.segsPg > .title {
    margin-bottom: 20px;
    font-size: var(--font-size-title);
}
.gCrd.emSegCrd {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    max-width: 1000px;
    /* border: 1px solid #e0e0e0; */
}

.gCrd.emSegCrd > .cm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.gCrd.emSegCrd > .cm > div {
    cursor: pointer;
    display: flex;
}
.gCrd.emSegCrd > .lt > p {
    margin: 0;
}
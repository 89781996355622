.sdMdl.sgmSnglMdl > .ctnt {
  max-width: 900px;
  width: 80%;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp {
  max-width: 100%;
}

.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap {
  padding: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap.noBorder {
  border-bottom: 0;
}


.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .errorRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .titleRow {
  margin-bottom: 15px;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .titleRow > p.title {
  color: var(--primary-text-color);
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .titleRow > p.info {
  color: var(--primary-text-color-light);
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .nameRow > .itemBox > .gErr {
  margin-top: 5px;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow > .itemBox {
  flex: 1;
  display: flex;
  flex-direction: column;

}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow > .itemBox > .gLabel {
  color: var(--primary-text-color);
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow > .itemBox > .gLabelInfo {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--primary-text-color-light);
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow > .itemBox > .gInput {
  max-width: 300px;
}


.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetRow {
  max-width: 300px;
  margin-bottom: 0px;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetRow > .targetBox {
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetRow > .targetBox > label {
  color: var(--primary-text-color);
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}

.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetDateRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetDateRow > .itemBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  1max-width: 300px;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetDateRow > .itemBox > .gLabelInfo {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--primary-text-color-light);
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetDateRow > .itemBox > .gInput {
  max-width: 300px;
}


.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow > .itemBox.dtBox > .dateFilter {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow > .itemBox.dtBox > .dateFilter > .dateFilterLastMonth {
  margin-right: 10px;
  width: 300px;
}


.ant-picker-dropdown {
  z-index: 999999;
}


.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow.cstmTypVal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow.cstmTypVal > .lt,
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow.cstmTypVal > .rt {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow.cstmTypVal > .rt > .gLbl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .cardWrap > .targetTagRow.cstmTypVal > .rt > .gErr {
  margin-top: 5px;
}


.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .buttonsRow > button {
  padding: 8px 32px;
  text-align: center;
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
  cursor: default;
  border-radius: var(--generic-border-radius);
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .buttonsRow > button.disabled {
  background-color: grey;
  cursor: not-allowed;
}
.sdMdl.sgmSnglMdl > .ctnt > .gPWrp > .gBCtnt > .snglSgmnt > .buttonsRow > button > .fa {
  margin-left: 5px;
}
.gPg.fdbGnrcPg {
  height: 100%;
  padding: 20px 0 0 20px;
}
.gPg.fdbGnrcPg  > .innerBox {
  height: calc(100% - 40px);
}
.gPg.fdbGnrcPg  > .innerBox > .contentBox {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  height: 100%;
}
.gPg.fdbGnrcPg  > .innerBox > .contentBox > .left {
  flex: 1;
  border-right: 1px solid #e6e6e7;
  padding: 0px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow-y: scroll;
}
.gPg.fdbGnrcPg  > .innerBox > .contentBox > .right {
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
}
.gPg.fdbGnrcPg  > .innerBox > .contentBox > .left::-webkit-scrollbar-track,
.gPg.fdbGnrcPg  > .innerBox > .contentBox > .right::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPg.fdbGnrcPg  > .innerBox > .contentBox > .left::-webkit-scrollbar,
.gPg.fdbGnrcPg  > .innerBox > .contentBox > .right::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPg.fdbGnrcPg  > .innerBox > .contentBox > .left::-webkit-scrollbar-thumb,
.gPg.fdbGnrcPg  > .innerBox > .contentBox > .right::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}


.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 20px);
}


.psdRadio {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #3498db;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox {
  background-color: #ffffff;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > p.title.highlight {
  background-color: yellow;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > p.secondaryMessage.highlight {
  background-color: yellow;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > p.postSubmissionThankYouMessage.highlight {
  background-color: yellow;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .prmQstnBx {
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 30px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .prmQstnBx.highlight {
  background-color: yellow;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .prmQstnBx > .iconRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .prmQstnBx > .iconRow > img {
  height: 30px;
  margin: 0 10px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .prmQstnBx > .iconRow.nps {
  gap: 4px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .prmQstnBx > .iconRow.nps >  .npsRtngBx {
  padding: 3px 5px;
  border-radius: var(--generic-border-radius);
  border: var(--generic-border);
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section {
  margin-bottom: 20px;
  width: 100%;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section.highlight {
  background-color: yellow;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .sectionTitle {
  font-weight: bold;
  margin-bottom: 20px;
  color: #3498db;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow {
  margin-bottom: 20px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow > .question {
  margin-bottom: 10px;
  font-weight: 600;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow > textarea {
  resize: none;
  width: 100%;
  border: 1px solid #e6e6e7;
  border-radius: 5px;
  padding: 10px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow >.radioOpts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow >.radioOpts.single-select {
  flex-direction: column;
  align-items: flex-start;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow >.radioOpts.multiple-out-of-5 {
  flex-direction: column;
  align-items: flex-start;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow > .radioOpts > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow >.radioOpts > img {
  width: 20px;
  height: 20px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow > .radioOpts.multiple-out-of-5 > .subquestion {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow > .radioOpts.multiple-out-of-5 > .subquestion > p {
  width: 200px;
  margin-bottom: 10px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow > .radioOpts.multiple-out-of-5 > .subquestion > .subRadioOpts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .section > .questionRow > .radioOpts.multiple-out-of-5 > .subquestion > .subRadioOpts > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .enabledRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  margin-bottom: 30px;
}




.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .contentBox > .gIpt > .opt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .contentBox > .gIpt > .opt input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 0 1px grey;
  margin-top: 10px;
  cursor: pointer;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .contentBox > .gIpt > .opt input[type="radio"]:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .contentBox > .gIpt > .opt label {
  display: block;
  cursor: pointer;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .contentBox > .gIpt > .opt > input[type="radio"]:hover {
  box-shadow: 0 0 0 2px #3498db;
  background-color: #3498db;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .contentRow > .contentBox > .gIpt > .opt > input[type="radio"]:checked {
  box-shadow: 0 0 0 2px #0d43aa;
  background-color: #0d43aa;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow.secondaryMessage {
  margin-bottom: 20px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow.primaryQuestion {
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 10px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .gSettingsRow.postSubmissionThankYouMessage {
  margin-top: 30px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .sctn {
  background-color: #f5f5f5;
  background-color: var(--sidebar-main-menu-active-bg);
  padding: 10px;
  margin-bottom: 20px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .sctn > .gSettingsRow.title > .titleRow > p {
  font-weight: bold;
  margin-bottom: 5px;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .adSctnBtn {
  cursor: pointer;
}
.gPg.fdbGnrcPg > .innerBox > .contentBox > .left > form > .adSctnBtn > span > i {
  margin-left: 5px;
}
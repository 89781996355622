.sdMdl.cmpgnDataMdl > .ctnt {
  max-width: 1400px;
  width: 90%;
}
.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp {
  max-width: 100%;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .cmpgStatsBx {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .cmpgStatsBx > div {
  flex: 1;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .cmpgStsBx {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .cmpgStsBx > .cmpgStsBtn {
  padding: 10px 20px;
  border-radius: var(--generic-border-radius);
  background-color: #f6f7fb;
  color: var(--primary-text-color);
  cursor: pointer;
}
.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .cmpgStsBx > .cmpgStsBtn.active {
  background-color: var(--sidebar-main-menu-active-bg);
  color: #002DAF;
  font-weight: bold;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
  flex-wrap: wrap;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 0;
  padding: 12px;
  width: 100%;
}
.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw.hdr {
  background-color: #f6f7fb;
  box-shadow: none;
  color: #838e9f;
  padding: 12px;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtPrsnl {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtNm {
  flex: 1;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtEml {
  /* width: 300px; */
  flex: 1;
  /* display: flex;
  justify-content: flex-start; */
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtChkIn {
  width: 140px;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtChkOut {
  width: 140px;
}
.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtNights {
  width: 60px;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtEmlStatus {
  width: 140px;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtTs {
  width: 200px;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtCtry {
  width: 120px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;
}

.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw.hdr > .dtClicks {
  width: 240px;
}
.sdMdl.cmpgnDataMdl > .ctnt > .gPWrp > .gBCtnt > .sgmDataBx > .dataRw > .dtClicks {
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

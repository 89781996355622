.gBoxWrap.sampleEmailBoxWrap > .npsInfoBox > .topBox > .titleBox > h4 {
  font-size: var(--font-size-title);
  margin-bottom: 30px;
}
.gBoxWrap.sampleEmailBoxWrap > .npsInfoBox > .contentBox > p {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}
.gBoxWrap.sampleEmailBoxWrap > .npsInfoBox > .contentBox > p > b {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-title);
  width: 100px;
}
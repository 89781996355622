.mb > .sb {
  p1osition: fixed;
  z-index: 50;
  transition: all 100ms linear;
  min-width: 196px;
  max-width: 196px;
  height: 100%;
  background-color: var(--sidebar-main-menu-bg);
  border-right: 1px solid var(--generic-border-color);
}
.mb > .sb.collapsed {
  min-width: 80px;
}

.sb > .fxd {

}
.sb > .fxd.top {
  height: 80px;
  margin-left: 10px;
  border-bottom: 1px solid var(--generic-border-color);
}
.sb.collapsed > .fxd.top {
  margin-left: 5px;
}
.mb.rp > .sb > .fxd.top {
  height: 60px;
  padding-top: 10px;
}

.mb.eg > .sb > .fxd.top {
  height: 60px;
  padding-top: 10px;
}
.mb.eg > .sb.collapsed > .fxd.top {
  height: 60px;
}
.mb.eg > .sb > .fxd.top.locSlctd {
  height: 100px;
}
.mb.eg > .sb.collapsed > .fxd.top.locSlctd {
  height: 140px;
}

.mb.mk > .sb > .fxd.top {
  height: 60px;
  padding-top: 10px;
}
.mb.rg > .sb > .fxd.top {
  height: 60px;
  padding-top: 10px;
}
.sb > .fxd.bottom {
  height: 60px;
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid var(--generic-border-color);
}
.sb > .fxd.top > .prdRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 40px;
  font-size: var(--font-size-top-bar);
  font-weight: var(--font-weight-bold);
  text-transform: capitalize;
}
.sb > .fxd.top > .prdRow > .pTtl {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.sb > .fxd.top > .aRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  height: 40px;
}
.sb.collapsed > .fxd.top > .aRow {
  flex-wrap: wrap;
  height: 80px;
  gap: 12px;
  gap: 6px;
  max-width: 69px;
}
.sb > .fxd.top > .aRow > .mTg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  border-radius: var(--generic-border-radius);
}
.sb > .fxd.top > .aRow > .mTg.fa {
  padding: 6px 8px;
}
.sb > .fxd.top > .aRow > .mTg:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.sb.collapsed > .fxd.top {
  height: 120px;
}

.sb > .fxd.bottom > .prdRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  height: 100%;
  text-transform: capitalize;
}
.sb > .fxd.bottom > .prdRow.sts > a {
  text-decoration: none;
  color: inherit;
  font-weight: var(--font-weight-bold);
  color: var(--sidebar-text-color);
}

.mb.rp > .sb > .mn {
  height: calc(100% - 61px - 10px - 50px);
  overflow-y: scroll;
}
.mb.eg > .sb > .mn {
  height: calc(100% - 61px - 51px - 50px);
}
.mb.mk > .sb > .mn {
  height: calc(100% - 61px - 10px - 50px);
}
.mb.rg > .sb > .mn {
  height: calc(100% - 61px - 10px - 50px);
}
.sb.collapsed > .mn {
  height: calc(100% - 141px - 61px - 50px);
}
.mb.eg > .sb.collapsed > .mn {
  height: calc(100% - 141px - 61px - 50px)!important;
}
.mb.mk > .sb.collapsed > .mn {
  height: calc(100% - 61px - 61px - 50px)!important;
}
.sb > .mn {
  overflow-y: scroll;
  overflow-x: hidden;
}
.sb > .mn::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #ffffff;
}
.sb > .mn::-webkit-scrollbar
{
  height: 6px;
	width: 3px;
	background-color: #ffffff;
}
.sb > .mn::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	background-color: #BDBEC1;
}

.sb > .fxd > .prdRow {
  display: flex;
  flex-direction: row;
}
.sb > .mn > ul#menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sb > .mn > #menu > li {
}
.sb > .mn > #menu > li.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
.sb > .mn > #menu > li.sh {
  padding: 20px 10px 0px 10px;
}
.sb.collapsed > .mn > #menu > li.sh {
  display: none;
}
.sb > .mn > #menu > li.sh > p {
  font-size: 12px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  opacity: 0.7;
}
.sb > .mn > #menu > li.ss {
  border-bottom: 1px solid #e6e6e7;
}
.sb > .mn > #menu > li > a {
  font-weight: var(--font-weight-bold);
  color: var(--sidebar-text-color);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.sb > .mn > #menu > li.ss.upgd > div {
  font-weight: var(--font-weight-bold);
  color: var(--sidebar-text-color);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  cursor: default;
}
.sb > .mn > #menu > li.ss.upgd > div > img {
  margin: 10px;
}

.sb.collapsed > .mn > #menu > li > a {
  justify-content: center;
}
.sb > .mn > #menu > li > a > img {
  margin: 10px;
  height: 20px;
}
.sb > .mn > #menu > li > a > i.fa {
  margin: 10px;
}
.sb > .mn > #menu > li > a > span {
  c1olor: var(--primary-text-color-light);
  font-weight: var(--font-weight-bold);
}
.sb > .mn > #menu > li > a span.unrCnt {
  background-color: var(--gte-primary-color);
  border-radius: 10px;
  position: relative;
  top: -11px;
  right: 14px;
  height: 18px;
  min-width: 18px;
  background-color: #ba73f1;
  color: #FFFFFF;
  border-radius: 10px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sb > .mn > #menu > li.active > a {
  color: var(--sidebar-text-color-active);
  color: #002DAF;
}

.sb.collapsed > .mn > #menu > li > a > span {
  display: none;
}
.sb.collapsed > .mn > #menu > li.ss.upgd > div > span {
  display: none;
}
.sb > .mn > #menu > li:hover {
  background-color: #CCCCCC;
  background-color: var(--sidebar-main-menu-active-bg);
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
}
.sb > .mn > #menu > li.sh:hover {
  background-color: transparent;
}

.sb > .fxd.bottom > .prdRow > a > img {
  margin: 10px;
}
.sb > .fxd.bottom > .prdRow > a > span {
  v1isibility: visible;
  t1ransition:visibility 0.3s linear;
}
.sb.collapsed > .fxd.bottom > .prdRow > a > span {
  display: none;
  v1isibility: hidden;
}
